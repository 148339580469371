import React from 'react';
import {
  GoodBad,
  GoodBadItem,
  Link,
  PageHero,
  PageWithSubNav,
  Paragraph,
  Section,
  SectionSubhead,
} from 'docComponents';
import pageHeroData from '../../../../data/pages/words.yml';

const IndexPage = () => {
  return (
    <PageWithSubNav pageType="design" title="Voice & Tone" subnav="words">
      <PageHero heroData={pageHeroData} tierTwo="Writing Guidelines" tierThree="Voice & Tone" />
      <Section>
        <Paragraph>
          That’s what makes Hudl’s voice human. No matter the channel or the specific choice of words, we always want
          Hudl to sound like a person.
        </Paragraph>
        <Paragraph>
          We serve all kinds of people (down-to-earth, passionate, sometimes indifferent) with products that help them
          do real work every day. Current customers enjoy Hudl because we built it with their personal interests in
          mind. Our voice should reflect that empathy.
        </Paragraph>
      </Section>
      <Section>
        <Paragraph>
          And while we may speak with a consistent <em>voice</em>, our <em>tone</em> adapts for each audience. It
          changes based on the context of how or when the content is presented.
        </Paragraph>
        <Paragraph>
          Think about your own voice and tone. Your voice is your personality. It’s unmistakably you. No matter who
          you’re talking to, you stick with the same vocabulary and point of view. Your tone, on the other hand, varies.
          It sets the mood.
        </Paragraph>
        <Paragraph>
          You wouldn’t talk to a middle-aged coach the same way you’d tweet at a newly committed athlete. Either the
          coach would think you’re being facetious or the athlete would call you boring. Emailing a parent about
          highlights is different than fielding a call from the athletic director whose invoice is due. Whether you
          realize it or not, your tone changes with each user.
        </Paragraph>
        <Paragraph>
          Just remember that our users are our primary consideration when writing as Hudl. Maintain the voice and an
          appropriate tone should come naturally depending on your recipient.
        </Paragraph>
        <Paragraph>You can also reference these keywords to understand who we are and are not.</Paragraph>
      </Section>

      <Section>
        <SectionSubhead>Relatable, Not Robotic</SectionSubhead>

        <GoodBad gridLayout="2">
          <GoodBadItem type="bad">
            <Paragraph>Hello,</Paragraph>
            <Paragraph>
              I apologize for the inconvenience. Payment for the 2016-2017 season is seven days overdue. The team
              account will remain locked until payment is received.
            </Paragraph>
          </GoodBadItem>
          <GoodBadItem type="good">
            <Paragraph>Hey Coach,</Paragraph>
            <Paragraph>
              Sorry you’re having trouble. It looks like your subscription was due last week. If we don’t receive
              payment on time, the account locks automatically.
            </Paragraph>
          </GoodBadItem>
        </GoodBad>

        <Paragraph>
          People respond better to other people than they do to software. Users love our products and support because
          they know the personalities behind it.
        </Paragraph>
      </Section>
      <Section>
        <SectionSubhead>Fun, Not Formal</SectionSubhead>

        <GoodBad gridLayout="2">
          <GoodBadItem type="bad">
            <Paragraph>
              Our offices are closed Thursday, November 24 for the Thanksgiving Holiday. Should you need service while
              we’re away, please email support@hudl.com.
            </Paragraph>
          </GoodBadItem>
          <GoodBadItem type="good">
            <Paragraph>
              Because we love food, football and time with family, our offices are closed for the holiday. Shoot us an
              email or tweet if anything comes up.
            </Paragraph>
          </GoodBadItem>
        </GoodBad>

        <Paragraph>
          We don’t take ourselves too seriously. We provide products that help users improve in areas they’re passionate
          about. It’s work, but it should be fun.
        </Paragraph>
      </Section>
      <Section>
        <SectionSubhead>Trustworthy, Not Ambivalent</SectionSubhead>

        <GoodBad gridLayout="2">
          <GoodBadItem type="bad">
            <Paragraph>
              We’re pretty sure uploading from a FireWire device won’t work. Instead, you could try a USB cable. That
              might make the experience a little smoother.
            </Paragraph>
          </GoodBadItem>
          <GoodBadItem type="good">
            <Paragraph>
              Our uploading tools don’t support video from a FireWire device. For the smoothest experience possible,
              stick with a USB connection.
            </Paragraph>
          </GoodBadItem>
        </GoodBad>

        <Paragraph>
          We have to be confident in what we’re saying. If we beat around the bush or put a qualifier before every
          thought, the user will second-guess our content.
        </Paragraph>
      </Section>
      <Section>
        <SectionSubhead>Informative, Not Condescending</SectionSubhead>

        <GoodBad gridLayout="2">
          <GoodBadItem type="bad">
            <Paragraph>
              Go to the Team tab and click Roster. Add the athlete’s email address, name, position - all of the basic
              information a coach would have. Click Add Athlete and you’re done.
            </Paragraph>
          </GoodBadItem>
          <GoodBadItem type="good">
            <Paragraph>
              To add new athletes to the roster, you’ll hover over Team and click Roster. There, you want to add email
              addresses first. That’s how the athlete is invited to Hudl.
            </Paragraph>
          </GoodBadItem>
        </GoodBad>

        <Paragraph>
          We can’t assume anything we’re telling them is common knowledge. By glossing over the ‘basics’, you could
          alienate our newest users. Talk to the reader as though you’re learning together.
        </Paragraph>
      </Section>
      <Section>
        <SectionSubhead>Inspirational, Not Judgemental</SectionSubhead>

        <GoodBad gridLayout="2">
          <GoodBadItem type="bad">
            <Paragraph>
              There’s no excuse for failure. You’ve worked hard to reach this point, why would you quit now? Nothing is
              worth giving up this dream.
            </Paragraph>
          </GoodBadItem>
          <GoodBadItem type="good">
            <Paragraph>
              You’ve worked too hard to turn back now. We know you have what it takes to be great. Remember that tonight
              as you take on one final opponent.
            </Paragraph>
          </GoodBadItem>
        </GoodBad>

        <Paragraph>
          It’s not our place to question a coach or athlete’s motives. We want to help them achieve whatever goal
          they’ve set. Come from a place of understanding—we’re all working towards something.
        </Paragraph>
      </Section>
      <Section>
        <SectionSubhead>Motivational, Not Bossy</SectionSubhead>

        <GoodBad gridLayout="2">
          <GoodBadItem type="bad">
            <Paragraph>
              You want to be the best – better than everybody else. You have to become stronger, faster and smarter to
              do it. Use Hudl to reach your goals. Don’t wait. Start now.
            </Paragraph>
          </GoodBadItem>
          <GoodBadItem type="good">
            <Paragraph>
              We’re all striving to be better at something. What’s your ultimate goal? What can you do to reach it?
              Whatever the answer, Hudl is here to help. Let’s get started.
            </Paragraph>
          </GoodBadItem>
        </GoodBad>

        <Paragraph>
          This one is all about word choice. Don’t be aggressive and push a user to want or do something. Tell them they
          “can” to spark the fire within. Speak directly to them (“you can”) for a greater impact.
        </Paragraph>
      </Section>
      <Section>
        <SectionSubhead>Celebratory, Not Cocky</SectionSubhead>

        <GoodBad gridLayout="2">
          <GoodBadItem type="bad">
            <Paragraph>
              Our teams are the best. More than 300 Hudl basketball programs won state championships this year. We’re
              pumped our tools could make the difference.
            </Paragraph>
          </GoodBadItem>
          <GoodBadItem type="good">
            <Paragraph>
              More than 300 Hudl basketball teams won state championships this year – and we couldn’t be prouder.
              Congratulations to every coach and athlete!
            </Paragraph>
          </GoodBadItem>
        </GoodBad>

        <Paragraph>
          We’re lucky to serve these coaches and athletes. Get excited for them, not with them. It’s their
          accomplishment, not ours. Admire them and remember: There are always more goals to accomplish.
        </Paragraph>
      </Section>
      <Section>
        <SectionSubhead>Fun-Loving, Not Cheesy</SectionSubhead>

        <GoodBad gridLayout="2">
          <GoodBadItem type="bad">
            <Paragraph>Your swing needs our Technique.</Paragraph>
          </GoodBadItem>
          <GoodBadItem type="good">
            <Paragraph>Give your swing a free upgrade.</Paragraph>
          </GoodBadItem>
        </GoodBad>

        <Paragraph>
          There’s a fine line between being light-hearted and cringe-worthy. Don’t try so hard it distracts the reader
          from your actual message.
        </Paragraph>
      </Section>

      <Section title="Tone">
        <Paragraph>
          Our tone adapts to match the context. It also changes to fit each of our target audiences. This section
          details the <em>general tone</em> we take with each of our core audiences.
        </Paragraph>
        <Paragraph>
          No matter the reader, Hudl’s voice should always embody the same persona: an ageless, enthusiastic, tech-savvy
          assistant coach.
        </Paragraph>

        <SectionSubhead>Coaches</SectionSubhead>

        <Paragraph>
          In talking to coaches, Hudl is a fellow coach. <strong>Straightforward but agreeable.</strong> We understand
          the stresses of coaching, and are always there to help in the simplest, quickest way possible. We work
          together towards the common goal of winning, while keeping the team’s best interests in mind.
        </Paragraph>

        <SectionSubhead>Analysts</SectionSubhead>

        <Paragraph>
          The analyst takes video and stats <em>very personally</em>. We want to make their lives easier without
          downplaying the significance of that role. Have <strong>intelligent and direct</strong> conversations focused
          on the value they provide a given team. Show them how clearly we see things from their perspective.
        </Paragraph>

        <SectionSubhead>Athletes</SectionSubhead>

        <Paragraph>
          Hudl is vibrant and relatable when writing for athletes. We celebrate their successes and feel the pain in
          their defeats. We engage with a casual tone, striving to <strong>entertain as often as we educate</strong>.
          We’re a constant in their athletic careers, documenting where they’ve been and inspiring them to keep going.
        </Paragraph>

        <SectionSubhead>Parents</SectionSubhead>

        <Paragraph>
          Parents know what’s best and we’re not here to argue. Their main concern is the athlete’s well-being, with
          success a close second. Hudl can empathize and provide resources to help them push for both. Strive to be
          relatable regardless of where the athlete (and parent) is in his or her career.
        </Paragraph>

        <SectionSubhead>General Public</SectionSubhead>

        <Paragraph>
          Hudl is a brand advocate, connecting fans with their favorite athletes and teams. We sift through the daily
          grind of analyzing video and stats to share only the greatest moments. We keep people engaged with the tools
          to view and share easily digestible updates, giving analysts more time to strategize and athletes more time to
          train.
        </Paragraph>
      </Section>

      <Section title="Additional Guidelines">
        <Paragraph>
          No matter who you’re talking to, or what channel you’re on, strive for{' '}
          <strong>clarity over cleverness</strong>. If you find yourself trying too hard to be funny or inspirational,
          chances are the reader will have just as much trouble understanding. Don’t be afraid to trim the fat and get
          your point across.
        </Paragraph>
        <Paragraph>
          Be careful with punctuation, <strong>especially exclamation points</strong>. Overusing exclamations can easily
          come across as yelling. Save exclamations for those “OMG” moments and statements that truly have the audience
          excited. Don’t tack one onto the end of an average sentence as a way of saying “We promise it’s interesting!”
          If you need to convey excitement, lean on words more than punctuation.
        </Paragraph>
        <Paragraph>
          We write the way we speak. When we speak, we use contractions.{' '}
          <strong>Use contractions in your writing.</strong> You’ll automatically sound less robotic.
        </Paragraph>
        <Paragraph>
          For more on how we prefer to punctuate,{' '}
          <Link href="/words/writing-guidelines/punctuation">check these guidelines</Link>.
        </Paragraph>
      </Section>
    </PageWithSubNav>
  );
};

export default IndexPage;
